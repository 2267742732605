import React from 'react';


const SaleComplete = ({ }) => {


  return (
    <div className={`SaleComplete `} style={{  }}>

      Thank you for your purchase - you should receive an email confirmation within a few minutes containing your digital download
    </div>
  );
};

export default SaleComplete;
