import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Input from '../forms/Input'
import Button from '../forms/Button'

// import valid_email from '../utils/regularExpressions'
import { valid_email } from '../utils/regularExpressions'

const Checkout = ({ redirect_url, clientSecret, required_info=[] }) => {

  let message = 'Instant email delivery upon purchase'

  let membership = Cookies.get('membership');

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) return; // Stripe.js has not loaded

      setIsLoading(true);
      setErrorMessage('')

      // TODO: check for required info instead of statically checking for email
      if(valid_email.test(email)){
        fetch(process.env.REACT_APP_API_URL + '/members/' +membership, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 'email':email })
        })

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: redirect_url,
                payment_method_data: {
                  billing_details: {
                    email: email
                  }
                },
                // redirect: "if_required",
            },
        });

        if (error) {
            setErrorMessage(error.message);
        }
      }else{
        setErrorMessage('Please Enter Valid Email')
      }

      setIsLoading(false);
  };
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (!stripe || !clientSecret) {
        console.log("Stripe not ready or clientSecret missing. Skipping fetch.");
        return; // Exit early if Stripe is not initialized or clientSecret is missing
      }

      try {
        stripe.retrievePaymentIntent(clientSecret.clientSecret)
        .then((res)=>{
          setAmount((res.paymentIntent.amount / 100).toFixed(2))
        })
      } catch (error) {
        console.error("Error retrieving PaymentIntent:", error);
      }
    };

    fetchPaymentIntent(); // Call the function
  }, [stripe, clientSecret]);

  return (
        <form>
          <div style={{textAlign:'left',fontSize:'2em',fontWeight:'bold'}}>${amount}</div>
          <div style={{textAlign:'left',fontSize:'1.25em', margin:'0 0 .25em 0'}}>{message}</div>
          <PaymentElement options={{
              fields: {
                billingDetails:{
                  email:'never'
                }
              }
            }}/>
          <Input label='Email' value={email} onChange={(ev)=>setEmail(ev.target.value)}/>
          <div style={{display:'flex', justifyContent:'flex-end', margin:'.5em 0'}}>Powered by<img src='/stripe_logo.svg#path4' style={{height:'1.5rem',display:'inline-flex', paddingLeft:'.5em', bottom:'0'}} /></div>
          <Button label={isLoading ? 'Processing...' : `Pay $${amount}`} disabled={!stripe || isLoading} onClick={handleSubmit}/>
          {errorMessage && <div>{errorMessage}</div>}

        </form>
  );
};

export default Checkout;
