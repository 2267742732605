import React from 'react';

import Cookies from 'js-cookie';
import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import StripeHeader from '../payment/StripeHeader'
import ImageScroller from '../components/ImageScroller'
import Footer from '../components/Footer'

import "./Product.css";

const Product = ({ }) => {
  let { product_id } = useParams();
  const [product, setProduct] = useState({})
  const [stripe_pk, setStripe_pk] = useState(null)



  let homeDir = '';

  let membership = Cookies.get('membership');


  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let deviceType;
  if (width <= 600) {
    deviceType = 'mobile';
  } else if (width <= 1024) {
    deviceType = 'tablet';
  } else {
    deviceType = 'desktop';
  }


  const fetchDataRails = () => {

    fetch(process.env.REACT_APP_API_URL + '/products/' +product_id, {
      headers: {
        'membership': Cookies.get('membership'),

        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);

        if(data && data.profile_id){
          fetchStripePk(data.profile_id);
        }


        if(data.membership && data.membership != null){
          Cookies.set('membership', data.membership)
        }

      });
  }

  useEffect(() => {
    fetchDataRails();
  },[]);

  const fetchStripePk = (profile_id) => {

    fetch(process.env.REACT_APP_API_URL + '/get_stripe_pk?profile_id='+profile_id, {
      headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
      },
      // body: {'profile_id' :profile_id},
    })
      .then((response) => response.json())
      .then((data) => {
        setStripe_pk(data.stripe_pk);
      });
  }

  return (
    <>
      <div className={`Product `} style={{  }}>

        <div class="ProductInfo">
          <h1>{product && product.name}</h1>

          <ImageScroller images={product.images}/>

          {product && (<div dangerouslySetInnerHTML={{ __html: product.description }}/>)}
        </div>

        <div class='PaymentBar'>
          {stripe_pk && product && (<StripeHeader stripe_pk={stripe_pk} required_info={['email']} products={{[product.id]:1}} redirect_url={`${window.location.origin}/sale_confirmation`}/>)}
        </div>
      </div>
      <Footer  footerText="Epicenters.io" link='https://epicenters.io'/>
    </>
  );
};

export default Product;
