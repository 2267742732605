import styles from './Hero2.css';

import Input from '../forms/Input';
import Button from '../forms/Button';
import Popup from '../components/Popup';

import PhoneBorder from '../components/PhoneBorder';
import React, {useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';

import { Helmet } from 'react-helmet-async';


const Hero2 = ({}) => {

  // document.title = "Epicenter | Software for Growing Your Creator Business"
  // document.querySelector('meta[name="description"]').setAttribute("content", "Personal Websites, Smart Links, Advanced Analytics, and Marketing Tools: all built to increase your revenue as a creator");

  const [waitlistVisible, setWaitlistVisible] = useState(false);
  const addToastMessage = useOutletContext();

  const [mockIdx, setMockIdx] = useState(0);
  let mocks = ['mock4.png','mock5.png']

  useEffect(() => {

    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }


  }, []);
  // addToastMessage({type:'Success', message:'Submitted', detail:'You should hear back within a few days'})


  const sendWaitlistSubmission = ()=>{
    window.emailjs.init({publicKey: "GtdfAhb79CyoEPGVI"});
    setWaitlistVisible(false);

    // these IDs from the previous steps
    window.emailjs.sendForm('service_mj99f3j', 'template_xivu9wm', '#waitlistForm')
        .then(() => {
            addToastMessage({type:'Success', message:'Submitted', detail:'You should hear back within a few days'})
        }, (error) => {
            addToastMessage({type:'Fail', message:'Error', detail:'Info not submitted - email justin@epicenters.io to join waitlist'})
        });
  }

  let stars = [];
  for(let i = 0; i < 20; i++){
    let randX = Math.random();
    let randY = Math.random();
    let randDelay = Math.random();
    stars.push(<div class="star" style={{"--xCoor":randX,"--yCoor":randY,"--delay":randDelay}}></div>);
  }

  const [displayForm, updateForm] = useState("ghost");
  const showForm = () => { updateForm("") }
  const hideForm = () => { updateForm("ghost") }

  // <h1>Simple <span style={{color:'var(--quarnary)'}}>Software</span> To Increase<br/>Your Revenue as a <span style={{color:'var(--quarnary)'}}>Creator</span></h1>
  // <p>Ensure the best result by turning your online traffic into subscribers/customers/partners</p>
  // <h1>Become a <span style={{color:'var(--quarnary)'}}>Boss</span> Through Your <span style={{color:'var(--quarnary)'}}>Passion Project</span></h1>


  // <h1>Be The <span style={{color:'var(--quarnary)'}}>Boss</span> of Your <span style={{color:'var(--quarnary)'}}>Creator Business</span></h1>
  // <p>We build software to generate <span style={{fontWeight:'bold',color:'var(--quarnary)'}}>revenue</span> for your business and empower you to pursue your <span style={{fontWeight:'bold',color:'var(--quarnary)'}}>passion</span></p>



  return (
    <>
      <Helmet>
        <title>Epicenter | Software for Growing Your Creator Business</title>
        <meta
          name="description"
          content="Personal Websites, Smart Links, Advanced Analytics, and Marketing Tools: all built to increase your revenue as a creator"
        />
      </Helmet>
      <div className="Hero2">

        {stars}

        <div  class="main">
          <div class="img">
            <img src="graph.png" style={{width:"90%"}}></img>
          </div>

          <div class="content">
            {/* On a mission to make everyone a creator */}
            <h1 style={{textTransform:'capitalize'}}>Tired Of <span style={{color:'var(--quarnary)'}}>Mediocre</span> Business Results As A <span style={{color:'var(--quarnary)'}}>Creator</span>?</h1>
            { false && (<h1 style={{textTransform:'capitalize'}}>Unsure what is next in <span style={{color:'var(--quarnary)'}}>growing</span> your business as a <span style={{color:'var(--quarnary)'}}>creator</span>?</h1>)}
            { false && (<p>Run, Grow, and Optimize your solo business with purpose built software and a tailor made plan of action</p>)}
            <p>Swap to the platform that removes the guess work of turning followers into paying customers and puts more money in your pocket</p>

            <div style={{marginLeft:'0'}} class='callToAction' onClick={()=>setWaitlistVisible(true)} >Register</div>

          </div>

          <div class="info">
            <div style={{textAlign:'center'}}>
              <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/website.png"/>
              Stand out with a website that highlights what makes you unique to your customers
            </div>
            <div style={{textAlign:'center'}}>
              <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/tools.png"/>
              Make it easy for your customers to support you through innovative web-tools
            </div>
            <div style={{textAlign:'center'}}>
              <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/email.png"/>
              Protect your business against algorithm changes by owning your marketing and sales channels
            </div>

          </div>

          <div class="mocks">
            <div>
              <div onClick={()=>setMockIdx(prevState => (mocks.length + prevState-1) % mocks.length)}>{'<'}</div>
              <img src={mocks[mockIdx]} />
              <div onClick={()=>setMockIdx(prevState => (mocks.length + prevState+1) % mocks.length)}>{'>'}</div>
            </div>
          </div>
        </div>

        <Popup  visible={waitlistVisible} setVisibility={setWaitlistVisible}>
          <form id="waitlistForm" style={{background:"#141417ff", padding:'1em'}}>
            <Input name='fullName' label='Full Name*' />
            <Input name='email' label='Email*' />
            <Input name='socialMediaHandle' label='Social Media Handle' />
            <Input name='personalWebsite' label='What main goal would you like your website to achieve?' />
            <Button label='Submit' onClick={(event)=>{event.preventDefault();sendWaitlistSubmission();}} />
          </form>
        </Popup>

      </div>
    </>
  )
};

export default Hero2;
