import styles from './Pricing.css';

import React, {useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';


const Pricing = ({}) => {

  let apps = [
    {'file':'epicenters.svg#layer1','name':'Epicenter','description':'Your own personal website built to increase conversions'},
    {'file':'smart_links.svg#layer1','name':'Smart Links','description':'Create dynamic QR codes and trackable short links'},
    {'file':'affiliate_directory.svg#layer1','name':'Affiliate Directory','description':'Subscriber app that provides easy access to your affiliate codes and links'},
    {'file':'outbound.svg#layer1','name':'Outbound','description':'Message your subscribers through text and email'},
    {'file':'hq.svg#layer1','name':'HQ','description':'See analytics regarding page performance'},
    // {'file':'bridge.svg#layer1','name':'Bridge','description':'Subscriber app to provide easy access to your affiliate codes and links'},
    // {'file':'forms.svg#layer1','name':'Forms','description':'Subscriber app to provide easy access to your affiliate codes and links'}
  ]



  return (
    <>
      <Helmet >
        <title>Pricing - Get all Epicenter tools for one low rate</title>
        <meta
          name="description"
          content="We're bundling all of our tools for one flat monthly rate, so you don't have to fret over which subscription will grow your business the most"
        />
      </Helmet>
      <div className="Pricing">

        <div>
          <h1>Flat rate for all tools</h1>
          <p>
            The Epicenter family of tools is continuously growing and improving.
            Not every attribute is highlighted below, so reach out with any questions.
            Get all these tools for only $25 per month.
            Sign up for our waitlist to secure this offer.
          </p>
        </div>

        <div class="PricingCard" style={{display:'none'}}>
          <div class="header">
            <div class="title">
              Entrepreneur Plan
            </div>
            <div class="price">
              $25/mo
            </div>
          </div>
          <div class="list">
            <li>Customizable Personal Site</li>
            <li>Link Shortener</li>
            <li>QR Code Maker</li>
            <li>10 Dynamic QR Codes</li>
            <li>Visitation Analytics</li>
            <li>Link Performance Analytics</li>
            <li>Email Acquisition</li>
            <li>Affiliate Code Page for your Followers</li>
          </div>
        </div>


        <div class="Apps">

          {apps.map((app, idx) =>{return(

            <div class='AppGrid'>
              <div style={{gridArea: 'icon', background:'var(--primary)', display:'flex', flex: '1 1 auto', aspectRatio:'1/1', borderRadius:'1em', height:'100%'}}>
                <svg class="" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" style={{fill:'var(--quarnary)', aspectRatio:'1/1', maxHeight:'90%', maxWidth:'90%', margin:'auto'}}>
                  <use href={app.file}></use>
                </svg>
              </div>
              <div style={{gridArea: 'name', fontSize:'2em', fontWeight:'bold'}}>{app.name}</div>
              <div style={{gridArea: 'description'}}>{app.description}</div>
            </div>

          )})}

        </div>


        <div style={{margin:"2em", display:'none'}}>
          Get a FREE metal business card and more when you commit to a year long subscription!
        </div>

      </div>
    </>
  )
};

export default Pricing;
