import styles from './Blog.css'
import BlogArticle from './BlogArticle'

import React, {useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

const Blog = ({}) => {


  let { slug } = useParams();

  let articles = {'simplify-your-link-in-bio': {
      title:'Your Link in Bio is Too Complicated',
      preview:`3 tips for improving outbound traffic from your social media page`,
      author:'Justin McGowen',
      date:'2024-04-27',
      published:true,
      content: [
        <p>
          Now before you freak out, you may have a link in bio that is actually simple enough for your followers to use.
          There are a lot out there that convert well which is why they are still widely popular this long after they first started appearing.
        </p>,
        <p>
          But... just in case, lets go through the key offenders so you can avoid them.
        </p>,
        <h3>
          1. Paradox of choice
        </h3>,
        <p>
          I'm sure you can think back to a time where you are scrolling through Netflix or a similar streaming platform that you've had for a while.
          The list of titles is endless but no matter how far you get in the infinite scroll, there is nothing that peaks your interest enough to start it.
        </p>,
        <p>
          I see this problem very frequently - too many links on link in bio pages.
          New followers will not be able to take in all of this information that you are giving them and they will end up giving up before clicking on ANY of the helpful links you provide them.
          This is the paradox of choice: too many options actually make it harder for someone to choose what they want from the list.
          Link in bios were first meant to have many links for putting everything you do in one place, but don't let this canibalize your engagement.
        </p>,
        <h3>
          2. No Link Prioritization
        </h3>,
        <p>
          Now there's a lot of link in bio tools that actually don't give you much flexibility on this point.
          In our businesses, there's often one action that a new customer/follower can take that is more valuable than other actions they might take.
          For example, a sale is likely better than having them follow you on another platform.
        </p>,
        <p>Because of this, we want to suggest this high value action early on and be more loud about this than other actions they might take.
        Sure, you will have people go to your website just so they can find where to follow you on Instagram, but for people who are less sure, why not make it big and bold that they should buy something from you - or give you their email for weekly value delivered straight to their inbox.</p>,
        <h3>
          3. Missing Value Proposition
        </h3>,
        <p>
          This is another one that I see so much, mainly because it is a skill to come up with succinct value propositions.
          I'll go to the website that someone has linked in their profile and very frequently see "Website", "Instagram", "Patreon" - and that is all the info I get without actually clicking on all those links.
          If someone doesn't have a clear reason to perform an action on your website, you are likely not getting the traffic you could to these other pages.
        </p>,
        <p>
          Make it clear what value someone will get by continuing through your funnel that is your link in bio.
          Maybe you can start with problem first language:
          Instead of "Buy My Fanart", propose a solution to the problem that your product solves: "Replace your old, boring screensavers".
        </p>,
        <hr/>,
        <p>
          I'm sure this resonated with a lot of you so if you want to take a bigger step in your link in bio overhaul, send us an email to sign up to beta test the Epicenter link in bio page.
          Its highly flexible and built with these concepts from the start so you are sure to see a bump in sales/emails/whatever you are trying to accomplish with your social media links.
        </p>
      ]
    },
    'making-content-in-the-age-of-ai': {
        title:'Making Content in the Age of AI',
        preview:`The internet is getting flooded fast with AI content - how can you stay above it all?`,
        author:'Justin McGowen',
        date:'2024-05-15',
        published:true,
        content: [
          <p>
            AI is hot right now. Tons of people are exploring the capabilities of new tools that utilize AI models.
            And whenever there is a gold rush - there are always people selling shovels.
          </p>,
          <p>
            One of the common shovel selling practice I see today is when people suggest that you use AI to write content for social media, blogs, videos and newsletters.
          </p>,
          <p>
            Don't get me wrong - if we were still in an era where AI was not widely available to the internet, this would be a leg up.
            People who use AI could generate content much faster to increased traffic to these sources faster than their competitors would be able to.
          </p>,
          <p>
            The problem is - AI generated content does not make your brand special. Chat GPT and others are widely available.
            Your customers don't need you to generate AI content - because they are more than capable of doing it themselves.
          </p>,
          <p>
            On top of that - platforms that have historically been used to generate traffic to your business are using AI too.
          </p>,
          <p>
            Google is starting to reduce the traffic it sends to your sites. It's AI gets priority over user's search traffic.
            In recent months, many sites have noticed a drop in traffic due to Google's AI answering the user's query without needing to leave Google.
            Many are calling this the end of SEO.
          </p>,
          <h3>
            So what do you do?
          </h3>,
          <p>
            Many people are looking for a solution to their decrease in traffic and even if the platform you are using hasn't been impacted by AI content yet - it will be.
            This response may not be a long term solution, but I think I've worked out the best current solution:
          </p>,
          <p>
            Write about real, human experiences.
          </p>,
          <p>
            While current models are really good at re-hashing things that are already in its database, there is one thing that AI is yet to be able to widely accomplish.
            AI is not able to have real human experiences.
          </p>,
          <p>
            Instead of writing "The fastest way to start a business in 2024", consider inserting your own experience: "How I grew my business in 2024"
          </p>,
          <p>
            The edge we still have over AI as business owners is that we can experience the messy, unsanitized data that is the world and build our collection of experiences.
            AI is only capable of interpreting very limited aspects of these experiences.
            And while it can make up fake scenarios, people who consume this content can tell when an AI is in charge of this type of story telling.
          </p>,
          <p>
            Now, I don't believe this will be the case forever.
            In the future, AI will be able to intake an increasing amount of data and I forsee scenarios where AI becomes your personal camera person + social media manager + strategist, all in one.
          </p>,
          <p>
            And this may be a good avenue for AI developers to move towards due to the current limitations that today's AI face.
            Let me know if you start a business like this (think Rabbit AI)
          </p>,
          <p>
            But for now, THIS is your competetive edge. AI cannot experience the world as you do today.
          </p>,
          <p>
            Have real experiences and reflect on them.
            Through your reflection, you will figure out what parts of that experience are valuable.
            Write about the value you found. In turn, others will appreciate this value enough to invest into your business.
          </p>
        ]
    },
    'the-6-online-money-making-strategies': {
        title:"Don't Waste Time on Side Hustles that are Destined to Fail",
        preview:`Breaking down the 6 avenues of making money online`,
        author:'Justin McGowen',
        date:'2024-05-26',
        published:false,
        content: [
          <p>
            If you spend any time on the internet, you will undoubtedly come across people showing you how they make money online.
            How do you know if they are legit though?
            We are going to break down the 6 different types of online hustles so that you can be more informed about which one is right for you.
          </p>,
          <h3>
            Attention Generation
          </h3>,
          <p>
            Everything is sales. Most interractions online are motivated by a financial transaction.
            Once you realize this, we can start to break down which part of the sales cycle each hustle falls into.
          </p>,
          <p>
            In Attention Generation, your product is content.
            Influencers, content creators, and most bloggers fall into this category.
            In this hustle, the goal is to have users engage with a platform.
            Once this happens, advertizers on the platform can create impressions of their product to the users who are engaging with your content.
          </p>,
          <p>
            This online revenue source benefits from being very easy to start.
            The barrier to enter is essentially non-existent as it can be as easy as uploading a video on youtube.
          </p>,
          <p>
            Unfortunately, as things are easy to try, competition increases.
            Everyone and their mom are becoming influences nowadays.
            The competition for people's attention is increasing and
          </p>,
          <h3>
            Donation (Paid Communities, Exclusive Access)
          </h3>,
          <h3>
            Advertising
          </h3>,
          <h3>
            Fulfillment on Demand
          </h3>,
          <h3>
            Selling a Product
          </h3>,
          <h3>
            Providing a Service
          </h3>
        ]
    }
  }

  return (
    <>
      <Helmet>
        <title>{slug ? articles[slug].title : 'Epicenter Blog - Your info hub to growing your online business'}</title>
        <meta
          name="description"
          content={slug ? articles[slug].preview : 'Articles on small business growth strategies and digital marketing hacks. Discover expert tips for solopreneurs and online business development.'}
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="Blog">



          {slug ? (
            <div style={{margin:"3em auto 0 auto", textAlign:'left', width:'60%', fontSize:'1.25em'}}>
              <h1>{articles[slug].title}</h1>
              {articles[slug].content}
              <Link to='/blog' class='Button'>More Articles</Link>
            </div>
          ) : (
            <div style={{margin:"3em auto 0 auto", textAlign:'left', width:'80%', fontSize:'1.25em'}}>
              <h1>Articles to help you optimize your online business</h1>
              <div class="BlogList">
                {Object.keys(articles).map((key, index) => {
                  if(articles[key].published){
                    return (
                    <Link to={`/blog/${key}`} class="BlogArticle">
                      <img src={articles[key].img ? articles[key].img : 'logo.png'}></img>
                      <h3>{articles[key].title}</h3>
                      <div>{articles[key].preview}</div>
                    </Link>)
                  }
                })}
              </div>
            </div>
          )}

      </div>
    </>
  )
};

export default Blog;
