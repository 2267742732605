import React from 'react';
import styles from './AffiliateLink.css'

const AffiliateLink = ({ url, code, title, text, img }) => {

return <a className='AffiliateLink' href={url} target='_blank'>
          <div class='AffiliateImg'></div>
          <div class='AffiliateTitle'>{title} ↗</div>
          <div class='AffiliateCode'>
            Code: <span style={{color:'var(--quarnary)', fontWeight:'bold'}}>{code}</span>
          </div>
          <div class='AffiliateDescription'>{text || url.split('//')[1]}</div>
        </a>;
}

export default AffiliateLink;
