import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const gTagUpdater = (tag = 'G-NHGF6XZWRY') => {
  if (window.gtag) {
    window.gtag("config", tag, {
      page_path: document.location.pathname,
      page_title: document.title,
    });
  }
}

// custom hook that observes title changes - uses to update google tag currently
function useTitleMutationObserver(gtag){
  const [currentTitle, setCurrentTitle] = useState('');

  useEffect(() => {
    const titleObserver = new MutationObserver(() => {
      const newTitle = document.title;
      if (newTitle !== currentTitle) {
        setCurrentTitle(newTitle);
        gTagUpdater(gtag);
      }
    });

    titleObserver.observe(document.head, { childList: true, subtree: true });

    return () => titleObserver.disconnect();
  }, [currentTitle]);

  return(currentTitle)
};

const GA4Scripts = ({ gtag }) => {


  useTitleMutationObserver(gtag)

  return (<Helmet >

    {/* Google Analytics 4 stream tag (gtag.js) */}
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`}></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${gtag}');
      `}
    </script>

  </ Helmet>)
}

export default GA4Scripts;
