import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import {loadStripe} from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Checkout from './Checkout'

const StripeHeader = ({ stripe_pk, redirect_url, required_info=[], products=null, cart=null }) => {
  var stripePromise = loadStripe(stripe_pk);
  const [clientSecret, setClientSecret] = useState('');

  let membership = Cookies.get('membership');

  useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + '/create_payment_intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products:products, member_id:membership }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.client_secret)});
  }, []);


  const appearance = {
    theme:'night',
    variables: {
      colorBackground:'#ddd',
      colorText:'black',
      borderRadius: '.5em',
      fontSizeBase: '1.125em',
    },
    rules: {
      '.Input':{
        border:'2px solid white',
        fontSize:'1rem',
        fontWeight: '400',
        padding: '0.5em 0.4em'
      },
      '.Input--empty':{
        backgroundColor:'#0000',
        color:'white'
      },
      '.Input::placeholder':{
        color:'white'
      },
      '.Label':{
        color:'#e8f3ff',
        fontSize:'1em'
      }
    }
  }

  return (
    <div>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }} appearance={{appearance}}>
          <Checkout required_info={required_info} redirect_url={redirect_url} clientSecret={{clientSecret}}/>
        </Elements>
      )}
    </div>
  )
}

export default StripeHeader;
