import styles from './ManagementHeader.css';
import MultiSelect from '../forms/MultiSelect';
import Input from '../forms/Input';
import Toggle from '../forms/Toggle';
import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams } from "react-router";
import { Outlet, Link } from 'react-router-dom';


const ManagementHeader = ({context, children}) => {

  let homeDir = '';

  const [profile, setProfile] = useState({id:''});
  const fetchLoggedIn = () => {
    // fetch table definition this is used to load form (we also use "value" of each key to submit data for both create and update form submission)
    fetch(process.env.REACT_APP_API_URL + '/tokens/' + Cookies.get('Authorization'), {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else if(response.status === 404) {
          return Promise.reject('error 404')
        }
      })
      .then((data) => {
        setProfile(data)
      })
      .catch(error => window.location.href = '/login');

  }
  useEffect(() => {
    fetchLoggedIn();
  },[]);

  return (
    <div className="ManagementHeader">

      <label class='hamburger'>
        <input type='checkbox' />
        <div></div>
      </label>
      <div class='menu'>
        <div class='header'>
          <img src='/logo.png' /> Epicenter
        </div>
        <Link to={`${homeDir}/admin/profiles/${profile['id']}`}  >Profile</Link>
        <Link to={`${homeDir}/admin/page_editor`} >PageEditor</Link>
        <Link to={`${homeDir}/admin/slugs`}  >Slugs</Link>
        <Link to={`${homeDir}/admin/pages`}  >Pages</Link>
        <Link to={`${homeDir}/admin/page_links`}  >PageLinks</Link>
        <Link to={`${homeDir}/admin/links`} >Links</Link>
        <Link to={`${homeDir}/admin/images`} >Images</Link>
        <Link to={`${homeDir}/admin/campaigns`} >Campaigns</Link>
        <Link to={`${homeDir}/admin/campaign_contents`} >Content</Link>
        <Link to={`${homeDir}/admin/products`} >Products</Link>
        <Link to={`${homeDir}/admin/analytics`} >Stats</Link>
      </div>

      <div style={{overflowY:'scroll'}}>

        <Outlet context={context} />
        {children}

      </div>
    </div>
  );
};

export default ManagementHeader;
