import React, { useState } from "react";
import "./ImageScroller.css";

const ImageScroller = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="image-scroller">
      <div className="main-image-container">
        <button className="nav-button prev" onClick={prevImage}>
          &#10094;
        </button>
        <img
          src={images && images[currentIndex] && images[currentIndex]['url']}
          alt={`Product ${currentIndex + 1}`}
          className="main-image"
        />
        <button className="nav-button next" onClick={nextImage}>
          &#10095;
        </button>
      </div>
      <div className="thumbnail-container">
        {images && images.map((image, index) => (
          <img
            key={index}
            src={image['url']}
            alt={`Thumbnail ${index + 1}`}
            className={`thumbnail ${
              index === currentIndex ? "active-thumbnail" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageScroller;
