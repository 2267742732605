import { useEffect } from "react";

export const DeepLinkRedirect = (queryParams = false) => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent || "";
    const isAndroid = /Android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

    const currentUrl = queryParams ? window.location.href : window.location.host + window.location.pathname ;
    console.log( window.location.pathname)
    console.log(currentUrl)

    if (isEmbeddedBrowser(userAgent)) {
      if (isIOS) {
        // Redirect to Safari using deep linking
        // const safariUrl = `http://${currentUrl.replace(/^https?:\/\//, "")}`;
        const safariUrl = `x-web-search://?${currentUrl}`
        window.location.href = safariUrl;
      } else if (isAndroid) {
        // Redirect to Chrome using deep linking
        // const chromeUrl = `googlechrome://${currentUrl.replace(/^https?:\/\//, "").replace(/^http?:\/\//, "")}`;
        // const chromeUrl = `intent:${currentUrl}`;
        // window.location.href = chromeUrl;

        // window.onbeforeunload = confirmExit;
        // function confirmExit(event)
        // {
        //   event.preventDefault();
        // }

        // this works (although user has to click)
        // document.write(`<a target="_blank" href="https://${currentUrl.replace(/^https?:\/\//, "")}" download id="open-browser-url"></a>`);
        // // window.stop();
        // let input = document.getElementById('open-browser-url');
        // if (input) {
        //     input.click();
        // }

      } else {
        // If unable to detect, prompt the user to open in a browser
        // alert("Please open this page in your default browser for the best experience.");
      }
    }
  }, []);

  const isEmbeddedBrowser = (userAgent) => {
    // Detect in-app browsers like Instagram, Facebook, TikTok, etc.
    const isInstagram = userAgent.includes("Instagram");
    const isTikTok = userAgent.includes("Tiktok");
    const isFacebook = userAgent.includes("FBAN") || userAgent.includes("FBAV");
    return isInstagram || isTikTok || isFacebook;
  };

  return null;
};